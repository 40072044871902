//import 'slick-carousel/slick/slick.min';

export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    // faqs    
    jQuery('dl dd').hide();

    jQuery('dt').on('click', function () {
      jQuery( this ).toggleClass('open');
      jQuery(this).next().slideToggle();
    });

    jQuery('dt').on('keyup',function(e){
      
      if (e.which == 13) {
        jQuery(this).trigger('click');
      }
    });

    jQuery('.currency-select input[type=radio]').on('change', function () {
      var currency = jQuery(this).val();
      jQuery('.currency').addClass('hidden');
      jQuery('.currency.' + currency).removeClass('hidden');
    });

    jQuery('.billing-select input[type=radio]').on('change', function () {
      var billing = jQuery(this).val();
      jQuery('.billing').addClass('hidden');
      jQuery('.billing.' + billing).removeClass('hidden');
    });

    const topDropMenus = document.querySelectorAll('.mobile-nav > .menu-item-has-children > a');

    for (let i = 0; i < topDropMenus.length; i++) { 
      topDropMenus[i].addEventListener('click', evt => {
        evt.preventDefault();
        
        for (let j = 0; j < topDropMenus.length; j++) {

          if (topDropMenus[j].parentElement.classList.contains('is-active')) {
            topDropMenus[j].parentElement.classList.remove('is-active');
          } else {
            if (i == j) {
              topDropMenus[j].parentElement.classList.add('is-active');
            }
          }
        }
      });
    }

    // jQuery('.slick-wrapper > div').slick({
    //   infinite: true,
    //   autoplaySpeed: 2000,
    //   arrows: true,
    //   dots: true,
    //   slidesToShow: 2,
    //   slidesToScroll: 2,
    //   // autoplay: false,
    //   // infinite: true,
    //   // centerMode: true,
    //   // variableWidth: true,
    //   responsive: [
    //     {
    //       breakpoint: 960,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         arrows: false,
    //         dots: true,
    //       },
    //     },
    //   ],
    // });



    // form slider & close button

    // if ( jQuery('.set-position-to-hero').length ) { 
    //if (jQuery('.set-position-to-hero').length) {
    //if (document.querySelector('.set-position-to-hero')) {
    // jQuery('.set-position-to-hero').insertAfter(jQuery('.is-style-hero-general .wp-block-image img'));
    //};

    
    // jQuery('.form-wrapper').addClass('hidden');
    // jQuery('.form-wrapper').append('<button class="close-wrapper" data-close aria-label="Close modal" type="button"></button>');
    // jQuery('.show-form').on('click', function () {
    //   jQuery('.form-wrapper').slideDown();
    //   document.querySelector('.form-wrapper').scrollIntoView({ behavior: 'smooth' });
    //   return false;
    // });

    // jQuery('.close-wrapper').on('click', function () {
    //   jQuery('.form-wrapper').slideUp();
    //   return false;
    // });
    

    //  // Hide Header on on scroll down
    //  var didScroll;
    //  var lastScrollTop = 0;
    //  var delta = 144;
    //  var navbarHeight = jQuery('.banner').outerHeight();
     
    //  jQuery(window).on('scroll', function () {
    //      didScroll = true;
    //  });
     
    //  setInterval(function() {
    //      if (didScroll) {
    //          hasScrolled();
    //          didScroll = false;
    //      }
    //  }, 250);
   
    //  function hasScrolled() {
    //      // fix for Safari: include body
    //      var st = jQuery('html').scrollTop() || jQuery('body').scrollTop();
         
    //      // Make sure they scroll more than delta
    //      if(Math.abs(lastScrollTop - st) <= delta)
    //          return;
         
    //      // If they scrolled down and are past the navbar, add class .nav-up.
    //      // This is necessary so you never see what is 'behind' the navbar.
    //      if (st > lastScrollTop && st > navbarHeight){
    //          // Scroll Down
    //          jQuery('.banner').removeClass('nav-down');
    //          jQuery('.banner').addClass('nav-up');
    //          jQuery('.mobile-menu').css('display', 'none');
    //          jQuery('.nav-wrapper .close-button').css('display', 'none');
    //          if ($(window).width() < 767 ) {
    //            jQuery('.nav-wrapper .open-button').css('display', 'block');
    //          }
    //      } else {
    //          // Scroll Up
    //          if(st + jQuery(window).height() < jQuery(document).height()) {
    //              jQuery('.banner').removeClass('nav-up');
    //              jQuery('.banner').addClass('nav-down');
    //          }
    //      }
         
    //      lastScrollTop = st;
    //  }
     //trigger("scroll")
    //  jQuery(window).on('scroll', function () {
    //    var scrollTop = jQuery(window).scrollTop();
    //    var imgPos = scrollTop / 2 + 'px';
    //    jQuery('.is-style-hero img').css('transform', 'translateY(' + imgPos + ')');
    //  });
     
     jQuery('.open-button').on('click', function () {
      jQuery('body').addClass('fixed');
      jQuery('.off-canvas-menu').css('transform', 'translateX(0)');
      jQuery('.hamburger span').removeClass('text-white');
      jQuery('.hamburger span').addClass('text-dark');
      jQuery('.open-button').css('display', 'none');
      jQuery('.close-button').css('display', 'block');
    });

    jQuery('.close-button').on('click', function () {
      // jQuery('.off-canvas-menu').css('display', 'none');
      jQuery('.off-canvas-menu').css('transform', 'translateX(100%)');
      jQuery('body').removeClass('fixed');
      jQuery('.close-button').css('display', 'none');
      jQuery('.open-button').css('display', 'block');
      jQuery('.hamburger span').removeClass('text-dark');
      jQuery('.hamburger span').addClass('text-white');
    });

    // get started button in mobile menu
    // jQuery('.menu-item-392').on('click', function () {
    //   jQuery('.off-canvas-menu').css('transform', 'translateX(100%)');
    //   jQuery('.close-button').css('display', 'none');
    //   jQuery('.open-button').css('display', 'block');
    //   jQuery('.hamburger span').removeClass('text-dark');
    //   jQuery('.hamburger span').addClass('text-white');
    // });

    if ( $('#resources-wrapper').length ) {
      $('#resources-wrapper').insertAfter( $('#resources-hook') );
    }

    if ( $('.openVideo').length ) {
      $('.openVideo').on('click', function () {	
          var videoURL = $(this).attr('href');
          if(!($('#videoIframe').length)) {	
              $('<iframe id="videoIframe" src="'+videoURL+'" frameborder="0" allowfullscreen></iframe>').appendTo('#videoWrapper');
                  }	 
        $('#videoModal').removeClass('hidden');
        $('#videoModal').addClass('flex');
        return false;
      });
    } 

    function closeVideoModal() {
      $('#videoModal').removeClass('flex');
      $('#videoModal').addClass('hidden');
      $('#videoIframe').remove();
    }

    jQuery('.close-modal-button').on('click', function () {	
      closeVideoModal();
    });

    jQuery('#videoModal').on('click', function () {	
      closeVideoModal();
    });
    
  },
};
